import React from "react";

const IndexPage = function () {
  return (
    <>
      <p>Welcome to Outer's Landers</p>
    </>
  );
};

export default IndexPage;
